import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { InView } from "react-intersection-observer";
import slugify from "slugify";

import ErrorHandler from "@/components/ErrorHandler";

import LocationCard from "./LocationCard";
import styles from "./index.module.scss";

class OtherLocations extends Component {
  renderHeader() {
    const { club, data } = this.props;

    const { webName } = club.fields.clubData.fields;

    return (
      <div className="col-md-16 px-0">
        {data.otherLocationsHeader
          ? data.otherLocationsHeader
          : `Other locations near ${webName}`}
      </div>
    );
  }
  renderOtherLocations() {
    const { data } = this.props;

    return data.clubs.map((c, index) => {
      const { fields } = c;

      if (fields) {
        const {
          fields: {
            thumbnail,
            webName,
            address,
            clubDetailPageURL,
            city,
            state,
            phoneNumber,
            zip,
            featuredAmenities,
            facilityId,
            region,
            status,
            type,
          },
        } = c;
        const altImageText = get(thumbnail, "fields.description", "");

        return (
          <LocationCard
            address={address}
            city={city}
            clubDetailPageURL={clubDetailPageURL}
            description={altImageText}
            facilityId={facilityId}
            featuredAmenities={featuredAmenities}
            key={webName}
            phoneNumber={phoneNumber}
            position={index + 1}
            region={region}
            state={state}
            status={status}
            thumbnail={thumbnail.fields.file.url}
            type={type}
            webName={webName}
            zip={zip}
          />
        );
      }
    });
  }
  render() {
    try {
      const { data } = this.props;
      const sectionSlug = slugify(
        get(data, "subNavLabel", "other-locations")
      ).toLowerCase();

      return (
        <section
          className={`${styles.otherLocationsContainer} module`}
          id={sectionSlug}
        >
          <InView
            as="div"
            onChange={(inView) => {
              if (inView) {
                /* console.log("Inview:", sectionSlug); */
              }
            }}
          >
            <div className="container">
              <div className="row">
                <div className={`col-md-12 ${styles.otherLocationsHeader}`}>
                  {this.renderHeader()}
                </div>
              </div>
              <div className="row">{this.renderOtherLocations()}</div>
              {/* each section is even and our custom grid is not divisable by 3 so this place holder works as an offset */}
              <div className={`col-lg-1 d-lg-block d-md-none d-sm-none`} />
            </div>
          </InView>
        </section>
      );
    } catch (error) {
      return <ErrorHandler componentName="OtherLocations" error={error} />;
    }
  }
}

// @TODO will need to revisit these proptypes as this does not reflect the actual structure but was the only wfor that wuld pass props validation
OtherLocations.propTypes = {
  club: PropTypes.exact({
    fields: PropTypes.object.isRequired,
    metadata: PropTypes.object.isRequired,
    sys: PropTypes.object.isRequired,
  }),
  clubData: PropTypes.shape({
    fields: PropTypes.shape({
      region: PropTypes.shape({
        fields: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      }),
      webName: PropTypes.string.isRequired,
    }),
  }),
  data: PropTypes.object.isRequired,
};

export default OtherLocations;
